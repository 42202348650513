
class Callbacks {
  static filterToFormHandler() {
    return function filterToForm(filter) {
      return {
        ...filter
      }
    }
  }
  static filterToStateHandler() {
    return function filterToState(state) {
      return {
      }
    }
  }
  static formToFilterHandler(record_id) {
    return function formToFilter(values, extra, meta, state) {
      return {
        trafficalert: record_id,
        ...values
      }
    }
  }

  static recordToFormHandler() {
    return function recordToForm(record) {
      return {
        ...record
      }
    }
  }

  static formToRecordHandler(record_id) {
    return function formToRecord(values, extra, meta) {
      return {
        trafficalert: extra?.trafficalert || record_id,
        ...values
      }
    }
  }
}

export default Callbacks
