const exportCompanycontractSpecificParameters = (values, state) => {
  const commercial_packages = _.get(values, 'commercial_packages')
  const companycontract_id = _.get(state, 'companycontract_id')
  const mode = _.get(values, 'mode')
  const commercialformula = _.get(values, 'commercial_formula')
  const companycontract_specificparameters = _.get(state, 'companycontract_specificparameters')
  const record_id = _.get(state, 'record_id')

  const exist_elems = _.map(
    _.filter(companycontract_specificparameters, ccsp => _.includes(commercial_packages, ccsp.commercialpackage)),
    elem => ({
      id: elem?.id,
      commercialpackage: elem.commercialpackage,
      commercialformula,
      companycontract: companycontract_id,
      mode,
      specific: record_id
    }))

  const new_elems = _.map(
    _.filter(commercial_packages, cp => !_.includes(_.map(companycontract_specificparameters, elem => elem.commercialpackage), cp)),
    commercial_package_id => ({
      commercialpackage: commercial_package_id,
      commercialformula,
      companycontract: companycontract_id,
      mode,
      specific: record_id
    }))

  return _.compact(_.flatten([exist_elems, new_elems]))
}

const exportCommercialpackageSpecificParameters = (values, state) => {
  const commercial_packages = _.get(values, 'commercial_packages')
  const mode = _.get(values, 'mode')
  const commercialformula = _.get(values, 'commercial_formula')
  const commercialpackage_specificparameters = _.get(state, 'commercialpackage_specificparameters')
  const record_id = _.get(state, 'record_id')

  const exist_elems = _.map(
    _.filter(commercialpackage_specificparameters, cpsp => _.includes(commercial_packages, cpsp.commercialpackage)),
    elem => ({
      id: elem?.id,
      commercialpackage: elem.commercialpackage,
      commercialformula,
      mode,
      specific: record_id
    }))

  const new_elems = _.map(
    _.filter(commercial_packages, cp => !_.includes(_.map(commercialpackage_specificparameters, elem => elem.commercialpackage), cp)),
    commercial_package_id => ({
      commercialpackage: commercial_package_id,
      commercialformula,
      mode,
      specific: record_id
    }))

  return _.compact(_.flatten([exist_elems, new_elems]))
}

const Aux = {
  exportCompanycontractSpecificParameters,
  exportCommercialpackageSpecificParameters
}

export default Aux
