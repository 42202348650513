import React from 'react'
import { hooks } from '@front/volcanion'
import { I18n } from '@front/volcanion/'

const withContainer = Component => props => {
  const [comment_to_driver, armrest, air_conditioning] = hooks.useFieldValues(['customerinfo.comment_to_driver', 'armrest', 'air_conditioning'])
  const [preferred_language] = hooks.useFormState('preferred_language')

  const MUSIC_LIST = hooks.useRelayConfig('MUSIC_LIST')
  const features = hooks.useRelayFeatures([
    'DISPLAY_PREF_WAITING_DURATION',
    'DISPLAY_PREF_PREFERRED_MUSIC',
    'DISPLAY_PREF_AIR_CONDITIONING',
    'DISPLAY_PREF_TEMPERATURE',
    'DISPLAY_PREF_OPEN_DOOR',
    'DISPLAY_PREF_OPEN_WINDOW',
    'DISPLAY_PREF_ARMREST',
    'DISPLAY_PREF_COMMENT_TO_DRIVER'
  ])

  const preferred_music_options = _.compact(_.map(MUSIC_LIST, music => ({
    label: music[preferred_language] || music['fr'],
    value: music[preferred_language] || music['fr']
  })))

  const waiting_duration_options = [
    { label: '15 min', value: 15 * 60 },
    { label: '30 min', value: 30 * 60 },
    { label: '45 min', value: 45 * 60 },
    { label: '1h', value: 60 * 60 },
    { label: '1h30', value: 90 * 60 },
    { label: '2h', value: 120 * 60 },
    { label: '2h30n', value: 150 * 60 },
    { label: '3h', value: 180 * 60 },
    { label: '4h', value: 240 * 60 },
  ]

  const open_door_options = [
    { label: I18n.t('ever'), value: 'always' },
    { label: I18n.t('preference.open_door_depends'), value: 'depends' },
    { label: I18n.t('never'), value: 'never' }
  ]

  const mergedProps = {
    comment_to_driver,
    armrest,
    air_conditioning,
    waiting_duration_options,
    preferred_music_options,
    open_door_options,
    ...features
  }
  return (
    <Component {...mergedProps} {...props} />
  )
}
export default withContainer
