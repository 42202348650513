import React from 'react'
import { I18n } from '@front/volcanion/'

import { Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'

import PaymentMethodList from './List'

const PaymentMethodSection = ({ disabled, record_id, ...props }) => <>
  <StepLabel>
    <Typography variant='h5'> {I18n.t('bank.payment_methods')} </Typography>
  </StepLabel>
  <StepContent >
    {!disabled && <Box sx={{ p: 2, boxShadow: 6, borderRadius: 1 }}>
      <Grid container rowSpacing={2}>
        <Grid item xs={12}>
          <PaymentMethodList record_id={record_id} />
        </Grid>
      </Grid>
    </Box>}
  </StepContent>
</>

export default React.memo(PaymentMethodSection)
