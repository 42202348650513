
import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => (props) => {
  const setFormValues = hooks.useFormChange()
  const [planned_order_enabled] = hooks.useFieldValues(['planned_order_enabled'])

  const required_delay = !!planned_order_enabled

  const enableDelay = useCallback((value) => {
    setFormValues([
      { field: 'min_delay', value: !!value ? 120 : undefined },
      { field: 'max_delay', value: !!value ? 90 : undefined },
    ])
  }, [setFormValues])

  const mergedProps = {
    required_delay,
    enableDelay,
  }
  return <Component {...mergedProps} />
}

export default withContainer
