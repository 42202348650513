import React, { useCallback } from 'react'

import { hooks } from '@front/volcanion'

const withContainer = Component => ({ disabled, ...props }) => {
  const isManager = hooks.useIsRoleManager()
  const [isReadOnly] = hooks.useFormState('isReadOnly')
  const setDeferred_billing = hooks.useFieldChange(['deferred_billing'])
  const auth_account = hooks.useFieldValue('customerinfo.auth_account')

  const handleAuthAccountChange = useCallback(value => {
    !value && setDeferred_billing('forbidden')
  }, [setDeferred_billing])

  const mergedProps = {
    isManager,
    disabled,
    auth_account,
    handleAuthAccountChange,
    isReadOnly,
  }
  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
