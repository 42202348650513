import React from 'react'

import { TextField } from '@front/squirtle'
import { Box } from '@mui/material'

const SearchForm = props =>
  <Box sx={{ display: 'none' }}>
    <TextField name='test' />
  </Box>

export default React.memo(SearchForm)
