import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'

import Callbacks from './callbacks'

const withContainer = Component => props => {
  const { openNotification } = hooks.useNotification()
  const [handleResetPasswordAction] = hooks.useModelFunction('stage', 'handleResetPasswordAction')
  const [handleWelcomeAction] = hooks.useModelFunction('stage', 'handleWelcomeAction')
  const [user_auth_id] = hooks.useFormState('user_auth_id')

  const resetPassword = useCallback(Callbacks.resetPasswordHandler(openNotification, handleResetPasswordAction), [openNotification, handleResetPasswordAction])
  const sendWelcomeEmail = useCallback(Callbacks.sendWelcomeEmailHandler(openNotification, handleWelcomeAction), [openNotification, handleWelcomeAction])

  const mergedProps = {
    user_auth_id,
    resetPassword,
    sendWelcomeEmail
  }

  return <Component {...mergedProps} {...props} />
}

export default withContainer
