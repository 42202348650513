import React from 'react'

import { Step, Box, Grid } from '@mui/material'
import { GridStepper } from '@front/squirtle'

import FormHeader from '@abra/elements/headers/Form'

import { ConfigsSection, PrimarySection, TranslationSection } from './Section/'

const TrafficAlertDetails = props => <>
  <Box>
    <Grid container justifyContent='center'>
      <Grid item xs={12} md={10}>
        <FormHeader goBackPath={'/traffic-alert'} labelKey={'traffic_alert'} />
        <GridStepper groupsCount={[3]} activeStep={0} orientation="vertical">
          <Step><PrimarySection /></Step>
          <Step><TranslationSection /></Step>
          <Step><ConfigsSection /></Step>
        </GridStepper>
      </Grid>
    </Grid>
  </Box>
</>

export default React.memo(TrafficAlertDetails)
