
import React from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {

  const [applicable_days] = hooks.useFieldValues(['applicable_days'])

  const mergedProps = {
    applicable_days,
  }


  return <Component {...mergedProps} />
}

export default withContainer
