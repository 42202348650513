import React, { useCallback, useEffect } from 'react'

import { hooks } from '@front/volcanion'
import { Loader } from '@front/squirtle'

import Callbacks from './callbacks'

const withContainer = Component => props => {
  const [, { openDialog }] = hooks.useDialogs()
  const isReady = hooks.useFormStatus('isReady')
  const [{ isManagingRecord, record_id }] = hooks.useFormState()
  const [client_number, gsm] = hooks.useFieldValues(['customerinfo.client_number', 'auth.gsm'])
  const DISPLAY_PREF = hooks.useRelayFeature('DISPLAY_PREF')
  const [user] = hooks.useModel('user', [record_id], { single: true, populate: ['auth'] })
  const displayResetPassord = !!record_id && !!user?.auth?.has_password
  const { EXTERNAL_PHONE_SERVICE } = hooks.useRelayConstants(['EXTERNAL_PHONE_SERVICE'])

  const confirmReset = useCallback(Callbacks.confirmResetHandler(openDialog, client_number, record_id), [openDialog, client_number, record_id])
  const onResetSmsPassword = useCallback(Callbacks.onResetSmsPasswordHandler(confirmReset), [confirmReset])
  const onResetEmailPassword = useCallback(Callbacks.onResetEmailPasswordHandler(confirmReset), [confirmReset])
  const confirmWelcomeEmail = useCallback(Callbacks.confirmWelcomeEmailHandler(openDialog, client_number, record_id), [openDialog, client_number, record_id])
  const handleCall = useCallback(Callbacks.handleCallHandler(EXTERNAL_PHONE_SERVICE), [EXTERNAL_PHONE_SERVICE])
  const onKeyDown = useCallback(Callbacks.onKeyDownHandler(gsm, handleCall), [gsm, handleCall])

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown)
    return () => {
      window.removeEventListener('keydown', onKeyDown)
    }
  }, [onKeyDown])


  const mergedProps = {
    DISPLAY_PREF,
    isManagingRecord,
    record_id,
    displayResetPassord,
    onResetSmsPassword,
    onResetEmailPassword,
    confirmWelcomeEmail
  }

  return <Loader isLoading={!isReady}>
    <Component {...mergedProps} {...props} />
  </Loader>
}

export default withContainer
