import React from 'react'
import { I18n } from '@front/volcanion'
import { TableCell } from '@mui/material'
import { ModelMenuSelectorField, MenuSelectorField, NumberField } from '@front/squirtle'

const Row = props => <>
  <TableCell size={'small'}>
    <ModelMenuSelectorField
      name='package'
      model_name='commercialpackage'
      labelKeys={['name_translated']}
      loadOnMount
      searchMode={'search'}
      allowNone
      noneLabel={I18n.t('commercialpackage.all')}
      noneFirst
    />
  </TableCell>
  <TableCell size={'small'}>
    <ModelMenuSelectorField
      name='formula'
      model_name='commercialformula'
      labelKeys={['name']}
      loadOnMount
      searchMode={'search'}
      allowNone
      noneLabel={I18n.t('commercial_formula.all')}
      noneFirst
    />
  </TableCell>
  <TableCell size={'small'}>
    <ModelMenuSelectorField
      name={'schedule'}
      model_name='schedule'
      labelKeys={['name']}
      searchMode='search'
      loadOnMount
      config={{
        forced_filter: { type: 'traffic' },
      }}
      required
    />
  </TableCell>
  <TableCell>
    <MenuSelectorField
      name={'schedule_type'}
      noneLabel={I18n.t('all')}
      options={[
        { label: I18n.t('order.immediate.short'), value: 'immediate' },
        { label: I18n.t('order.planned.short'), value: 'planned' }
      ]}
      allowNone
      noneFirst
    />
  </TableCell>
  <TableCell size={'small'}>
    <ModelMenuSelectorField
      name='zone'
      noneLabel={I18n.t('all_secondary')}
      model_name='zone'
      labelKeys={['name']}
      loadOnMount
      searchMode={'search'}
      allowNone
      noneFirst
    />
  </TableCell>
  <TableCell size={'small'} sx={{ maxWidth: 100 }}>
    <NumberField
      name='priority'
    />
  </TableCell>
</>

export default React.memo(Row)
