import React, { useCallback } from 'react'
import { ModelForm, hooks } from '@front/volcanion'

import Callbacks from './callbacks'

const withForm = Component => ({ record_id }) => {

  const requestSubmitConfig = hooks.useGlobalFormFunction('trafficalertconfig_list', 'requestSubmit')


  const formProps = {
    name: 'trafficalert_form',
    model_name: 'trafficalert',
    record_id,
    recordToState: useCallback(Callbacks.recordToStateHandler(), []),
    recordToForm: useCallback(Callbacks.recordToFormHandler(), []),
    onSubmitSuccess: useCallback(Callbacks.onSubmitSuccessHandler(requestSubmitConfig, record_id), [requestSubmitConfig, record_id]),
    populate: ['warning_trkey']
  }

  const mergedProps = {
  }

  return (
    <ModelForm {...formProps}>
      <Component {...mergedProps} />
    </ModelForm>
  )
}

export default withForm
