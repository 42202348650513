import React from 'react'
import { I18n } from '@front/volcanion/'

import { Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'
import { Icon, MenuSelectorField, SingleOptionSelectorField, TextField } from '@front/squirtle'

const Row1 = ({ waiting_duration_options, preferred_music_options, DISPLAY_PREF_WAITING_DURATION, DISPLAY_PREF_PREFERRED_MUSIC }) =>
  <Grid container spacing={1}>
    <Grid item xs hidden={!DISPLAY_PREF_WAITING_DURATION}>
      <MenuSelectorField
        name={'preference.waiting_duration'}
        options={waiting_duration_options}
        label={I18n.t('preference.waiting_duration')}
        getOptionProps={option => ({ color: 'primary' })}
        getOptionIconProps={option => ({ icon: 'hourglass_empty', fontSize: 'small' })}
      />
    </Grid>
    <Grid item xs hidden={!DISPLAY_PREF_PREFERRED_MUSIC}>
      <MenuSelectorField
        name={'preference.preferred_music'}
        options={preferred_music_options}
        noneLabel={I18n.t('none_fem')}
        label={I18n.t('preference.preferred_music')}
        getOptionProps={option => ({ color: 'primary' })}
        getOptionIconProps={option => ({ icon: option?.value ? 'music_note' : 'music_off', fontSize: 'small' })}
      />
    </Grid>
  </Grid>

const Row2 = ({ armrest, air_conditioning, open_door_options, DISPLAY_PREF_ARMREST, DISPLAY_PREF_OPEN_DOOR, DISPLAY_PREF_OPEN_WINDOW, DISPLAY_PREF_TEMPERATURE, DISPLAY_PREF_AIR_CONDITIONING }) =>
  <Grid container spacing={1}>
    <Grid item xs hidden={!DISPLAY_PREF_ARMREST}>
      <Typography sx={{ fontSize: 12, color: 'text.label', opacity: 0.8 }}>
        {I18n.t('armrest.label', { count: 2 })} :
      </Typography>
      <Box display={'flex'} alignItems={'flex-end'} >
        <Box sx={{ mb: '6px' }}>
          <Typography color={!armrest ? 'primary' : 'text.label'} sx={{ fontSize: 14 }}>
            {I18n.t('placement.down.label', { count: 2 })}
          </Typography>
        </Box>
        <SingleOptionSelectorField
          name={'preference.armrest'}
          valueType='switch'
          optionLabel={I18n.t('placement.up.label', { count: 2 })}
          disableTypography
          getOptionLabelProps={() => (
            { sx: { fontSize: 14, color: !!armrest ? 'primary.main' : 'text.label' }, labelPlacement: 'end' }
          )}
          formGroupProps={{ sx: { pt: 0, pl: 2 } }}
        />
      </Box>
    </Grid>
    <Grid item xs hidden={!DISPLAY_PREF_OPEN_DOOR}>
      <MenuSelectorField
        name={'preference.open_door'}
        options={open_door_options}
        allowNone={false}
        label={I18n.t('preference.open_door')}
        getOptionProps={option => ({ fontSize: 14 })}
      />
    </Grid>
    <Grid item xs hidden={!DISPLAY_PREF_OPEN_WINDOW}>
      <SingleOptionSelectorField
        label={`${I18n.t('preference.open_window')}, ${I18n.t('preference.open_window_extra')}`}
        name={'preference.open_window'}
        valueType='switch'
      />
    </Grid>
    <Grid item xs hidden={!DISPLAY_PREF_TEMPERATURE}>
      <TextField
        label={I18n.t('temperature.celcius')}
        name={'preference.temperature'}
        type={'number'}
        InputProps={{
          sx: { maxWidth: 70 },
          inputProps: { style: { textAlign: 'right' } },
          endAdornment: <Icon icon={'thermostat_auto'} />
        }}
      />
    </Grid>
    <Grid item xs hidden={!DISPLAY_PREF_AIR_CONDITIONING}>
      <SingleOptionSelectorField
        label={I18n.t('preference.air_conditioning')}
        labelPlacement={'end'}
        optionLabel={<Icon icon={'ac_unit'} sx={{ color: !!air_conditioning ? 'primary.main' : 'gray' }} />}
        getOptionLabelProps={option => ({ labelPlacement: 'end' })}
        name={'preference.air_conditioning'}
        valueType='switch'
        size={'small'}
      />
    </Grid>
  </Grid>

const Row3 = ({ comment_to_driver, DISPLAY_PREF_COMMENT_TO_DRIVER }) =>
  <Grid container spacing={1} hidden={!DISPLAY_PREF_COMMENT_TO_DRIVER}>
    <Grid item xs>
      <TextField
        name={'customerinfo.comment_to_driver'}
        label={I18n.t('comment.length.default', { count: (comment_to_driver || '').length })}
        placeholder={I18n.t('preference.comment_placeholder')}
        maxLength={250}
        multiline
        InputProps={{
          inputProps: { style: { fontSize: 14 } },
          startAdornment: <Icon icon={'speaker_notes'} />
        }}
      />
    </Grid>
    <Grid item xs>
      <TextField
        name={'customerinfo.comment_to_call_taker'}
        label={I18n.t('comment.operator', { count: 1 })}
        multiline
        InputProps={{
          inputProps: { style: { fontSize: 14 } },
          startAdornment: <Icon icon={'speaker_notes'} />
        }}
      />
    </Grid>
  </Grid >

const CommentSwection = props => <>
  <StepLabel>
    <Typography variant='h5'> {I18n.t('preference.label', { count: 2 })} </Typography>
  </StepLabel>
  <StepContent>
    <Box sx={{ p: 2, boxShadow: 6, borderRadius: 1 }}>
      <Grid container rowSpacing={2}>
        <Grid item xs={12}>
          <Row1 {...props} />
        </Grid>
        <Grid item xs={12}>
          <Row2 {...props} />
        </Grid>
        <Grid item xs={12}>
          <Row3 {...props} />
        </Grid>
      </Grid>
    </Box>
  </StepContent>
</>

export default React.memo(CommentSwection)
