import React, { useEffect } from 'react'
import { hooks } from '@front/volcanion'
import GeoUtils from '@front/volcanion/utils/geo'

const withContainer = Component => ({ disabled, ...props }) => {
  const [owner] = hooks.useFormState('record_id')
  const [home, searchHome] = hooks.useModelSearch('address', 'get', { mutator: address => GeoUtils.format(address) })
  const [work, searchWork] = hooks.useModelSearch('address', 'get', { mutator: address => GeoUtils.format(address) })

  const [favorites, searchFavorites] = hooks.useModelSearch('address', 'get', {
    sortBy: ['lastUsed'],
    sortOrders: ['desc'],
    mutator: address => GeoUtils.format(address)
  })

  const [recents, searchRecents] = hooks.useModelSearch('address', 'get', {
    sortBy: ['lastUsed'],
    sortOrders: ['desc'],
    mutator: address => GeoUtils.format(address)
  })

  useEffect(() => {
    if (!owner) return
    searchHome({ owner, type: 'home' })
    searchWork({ owner, type: 'work' })
    searchFavorites({ owner, favorite: 1, type: null })
    searchRecents({ owner })
  }, [owner])

  const mergedProps = {
    disabled,
    favorites,
    recents,
    home,
    work
  }

  return (
    <Component {...mergedProps} />
  )
}
export default withContainer
