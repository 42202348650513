class Callbacks {
  static recordToStateHandler() {
    return function recordToState(record) {
      return {
        title: _.get(record, 'name')
      }
    }
  }

  static recordToFormHandler() {
    return function recordToForm(record) {
      return {
        ...record
      }
    }
  }

  static formToRecordHandler() {
    return function formToRecord(values, extra, meta) {
      return {
        ...values
      }
    }
  }


  static onSubmitSuccessHandler(requestSubmitConfig) {
    return function onSubmitSuccess(result, values, extra, meta, state) {
      !!requestSubmitConfig && requestSubmitConfig({ trafficalert: result?.traffic_id || state?.record_id })
    }
  }
}

export default Callbacks
