import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Grid, Step } from '@mui/material'
import { GridStepper } from '@front/squirtle'

import FormHeader from '@abra/elements/headers/Form'

import {
  AddressSection,
  CustomerBillingSection,
  CompanyContractSection,
  OptionSection,
  PaymentMethodSection,
  PreferenceSection,
  PrimarySection,
  SecondarySection,
  SubscriptionSection,
} from './Section'

import Dialogs from './Popup'
import LoginSection from '@abra/frames/LoginSection'
import FormSpeedDial from '@abra/elements/speedDial/Form'
import OrderLimitationSection from '@abra/panels/OrderLimitation'

const User = ({ isManagingRecord, displayResetPassord, DISPLAY_PREF, onResetSmsPassword, onResetEmailPassword, confirmWelcomeEmail }) => {
  const actions = [
    { name: I18n.t('action.reset_password'), iconName: 'sms', onClick: onResetSmsPassword, hidden: !displayResetPassord },
    { name: I18n.t('action.reset_password'), iconName: 'alternate_email', onClick: onResetEmailPassword, hidden: !displayResetPassord },
    { name: I18n.t('email.welcome.label', { count: 1 }), iconName: 'alternate_email', onClick: confirmWelcomeEmail, hidden: !!displayResetPassord }
  ]

  return (
    <Box className='safearea'>
      <Dialogs />
      <Grid container justifyContent='center'>
        <Grid item xs={10} >
          <FormHeader goBackPath={'/client'} />
          <GridStepper groupsCount={[11]} activeStep={0} orientation="vertical">
            <Step> <PrimarySection /> </Step>
            <Step> <SecondarySection disabled={!isManagingRecord} /> </Step>
            <Step> <AddressSection disabled={!isManagingRecord} /> </Step>
            <Step> <OptionSection /> </Step>
            <Step> <OrderLimitationSection model='userorderlimit' primary_key='user_orderlimit_id' /> </Step>
            <Step> {!!DISPLAY_PREF && <PreferenceSection />}</Step>
            <Step> <CompanyContractSection /> </Step>
            <Step> <SubscriptionSection disabled={!isManagingRecord} />  </Step>
            <Step> <CustomerBillingSection disabled={!isManagingRecord} /> </Step>
            <Step> <PaymentMethodSection disabled={!isManagingRecord} /> </Step>
            <Step> <LoginSection tableProps={{ enableGlobalActions: false }} /> </Step>
          </GridStepper>
        </Grid>
      </Grid>
      <FormSpeedDial actions={actions} />
    </Box >
  )
}


export default React.memo(User)

