import React from 'react'
import { I18n } from '@front/volcanion/'
import { OptionUtils } from '@front/volcanion/utils'

import { Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'

import { ModelMenuSelectorField } from '@front/squirtle'

const Row1 = ({ driver_opts, vehicle_opts, ...props }) =>
  <Grid container spacing={1}>
    <Grid item xs>
      <ModelMenuSelectorField
        name={'customerinfo.driver_options'}
        label={I18n.t('options.useroption.label', { count: 1 })}
        model_name={'useroption'}
        searchMode={'search'}
        multiple
        loadOnMount
        config={{
          mutator: elem => ({ label: OptionUtils.getLabel(elem), ...elem })
        }}
      />
    </Grid>
    <Grid item xs>
      <ModelMenuSelectorField
        name={'customerinfo.vehicle_options'}
        label={I18n.t('options.vehicleoption.label', { count: 1 })}
        model_name={'vehicleoption'}
        searchMode={'search'}
        multiple
        loadOnMount
        config={{
          mutator: elem => ({ label: OptionUtils.getLabel(elem), ...elem })
        }}
      />
    </Grid>
  </Grid>

const OptionSection = props => <>
  <StepLabel>
    <Typography variant='h5'> {I18n.t('options.label', { count: 2 })} </Typography>
  </StepLabel>
  <StepContent >
    <Box sx={{ p: 2, boxShadow: 6, borderRadius: 1 }}>
      <Grid container rowSpacing={2}>
        <Grid item xs={12}>
          <Row1 {...props} />
        </Grid>
      </Grid>
    </Box>
  </StepContent>
</>

export default React.memo(OptionSection)
