import React, { useCallback } from 'react'

import Row from './Row'
import Header from './Header'
import { FormTable, TableHeader, TableRow } from '@front/squirtle'

const CompanyContractList = ({ record_id }) => {
  return (
    <FormTable
      model_name='companyuser'
      config={{
        populate: ['company.contracts', 'services'],
        initial_filter: {},
        forced_filter: { user: record_id },
        watchers: [record_id],
        validate_filter: useCallback(() => !!record_id, [record_id]),
        watch_type: 'index'
      }}
      tableProps={{
        enablePagination: false,
      }}
    >
      <TableHeader><Header /></TableHeader>
      <TableRow ><Row /></TableRow>
    </FormTable>
  )
}

export default React.memo(CompanyContractList)
