
import Aux from './Aux'

class Callbacks {
  static recordToFormHandler() {
    return function recordToForm(record) {
      const generic = record?.generic
      return {
        generic,
        name: _.get(record, 'name'),
        late_cancel_time: _.get(record, 'late_cancel_time'),
        included_waiting_minute: _.get(record, 'included_waiting_minute'),
        max_waiting_time: _.get(record, 'max_waiting_time'),
        noshow_max_rate: _.get(record, 'noshow_max_rate'),
        billing_criteria: {
          base_max: _.get(record, 'billing_criteria.base_max'),
          base_margin: _.get(record, 'billing_criteria.base_margin'),
          handling_max: _.get(record, 'billing_criteria.handling_max'),
          distance_max: _.get(record, 'billing_criteria.distance_max'),
          estimation_offset: _.get(record, 'billing_criteria.estimation_offset'),
          check_manual_dispatch: record?.billing_criteria?.check_manual_dispatch
        },
        immediate_order_enabled: _.get(record, 'immediate_order_enabled'),
        planned_order_enabled: _.get(record, 'planned_order_enabled'),
        min_delay: _.get(record, 'min_delay'),
        max_delay: _.get(record, 'max_delay') / 1440,
        dest_mandatory: _.get(record, 'dest_mandatory'),
        noshow_flat_rate: _.get(record, 'noshow_flat_rate') || 0,
        noshow_fixed_fee: _.get(record, 'noshow_fixed_fee') || 1,
        noshow_is_fixed: _.get(record, 'noshow_is_fixed'),
        waiting_minute_rate: _.get(record, 'waiting_minute_rate') || 0,
        late_cancel_flat_rate: _.get(record, 'late_cancel_flat_rate') || 0,
        late_cancel_minute_rate: _.get(record, 'late_cancel_minute_rate') || 0,
        correction_minute_rate: _.get(record, 'correction_minute_rate') || 0,
        tips: _.get(record, 'tips') || 0,
        discount: _.get(record, 'discount') || 0,
        commercial_packages: !!generic
          ? _.map(_.get(record, 'commercialpackage_specificparameters'), 'commercialpackage')
          : _.map(_.get(record, 'companycontract_specificparameters'), 'commercialpackage'),
        mode: !!generic
          ? _.get(_.head(_.get(record, 'commercialpackage_specificparameters')), 'mode')
          : _.get(_.head(_.get(record, 'companycontract_specificparameters')), 'mode'),
        commercial_formula: !!generic
          ? _.get(_.head(_.get(record, 'commercialpackage_specificparameters')), 'commercialformula')
          : _.get(_.head(_.get(record, 'companycontract_specificparameters')), 'commercialformula')
      }
    }
  }

  static formToRecordHandler() {
    return function formToRecord(values, extra, meta, state) {
      const generic = state?.generic
      const planned_order_enabled = _.get(values, 'planned_order_enabled')
      return {
        generic,
        name: _.get(values, 'name'),
        late_cancel_time: _.get(values, 'late_cancel_time'),
        included_waiting_minute: _.get(values, 'included_waiting_minute'),
        max_waiting_time: _.get(values, 'max_waiting_time'),
        noshow_max_rate: _.get(values, 'noshow_max_rate'),
        billing_criteria: {
          base_max: _.get(values, 'billing_criteria.base_max'),
          base_margin: _.get(values, 'billing_criteria.base_margin'),
          handling_max: _.get(values, 'billing_criteria.handling_max'),
          distance_max: _.get(values, 'billing_criteria.distance_max'),
          estimation_offset: _.get(values, 'billing_criteria.estimation_offset'),
          estimation_offset: _.get(values, 'billing_criteria.estimation_offset'),
          check_manual_dispatch: values?.billing_criteria?.check_manual_dispatch
        },
        immediate_order_enabled: _.get(values, 'immediate_order_enabled'),
        planned_order_enabled,
        min_delay: !!planned_order_enabled ? _.get(values, 'min_delay') : null,
        max_delay: !!planned_order_enabled ? _.get(values, 'max_delay') * 1440 : null,
        dest_mandatory: _.get(values, 'dest_mandatory'),
        noshow_flat_rate: _.get(values, 'noshow_flat_rate') || 0,
        noshow_fixed_fee: _.get(values, 'noshow_fixed_fee') || 1,
        noshow_is_fixed: _.get(values, 'noshow_is_fixed'),
        waiting_minute_rate: _.get(values, 'waiting_minute_rate') || 0,
        late_cancel_flat_rate: _.get(values, 'late_cancel_flat_rate') || 0,
        late_cancel_minute_rate: _.get(values, 'late_cancel_minute_rate') || 0,
        correction_minute_rate: _.get(values, 'correction_minute_rate') || 0,
        tips: _.get(values, 'tips') || 0,
        discount: _.get(values, 'discount') || 0,
        companycontract_specificparameters: !generic
          ? Aux.exportCompanycontractSpecificParameters(values, state)
          : undefined,
        commercialpackage_specificparameters: !!generic ?
          Aux.exportCommercialpackageSpecificParameters(values, state)
          : undefined
      }
    }
  }

  static getEmptyStateHandle(companycontract_id, commercial_package_id) {
    return function getEmptyState() {
      return {
        generic: !companycontract_id,
        companycontract_id,
        commercial_package_id
      }
    }
  }

  static getEmptyFormHandler() {
    return function getEmptyForm() {
      return {
        immediate_order_enabled: true,
        planned_order_enabled: true,
        dest_mandatory: true,
        min_delay: 120,
        max_delay: 90,
      }
    }
  }

  static recordToStateHandler(companycontract_id, commercial_package_id) {
    return function recordToState(record) {
      return {
        title: _.get(record, 'name'),
        generic: _.get(record, 'generic', !companycontract_id),
        companycontract_id: _.get(_.head(record?.companycontract_specificparameters), 'companycontract'),
        commercial_package_id: _.get(_.head(record?.commercialpackage_specificparameters), 'commercial_package'),
        commercialpackage_specificparameters: record?.commercialpackage_specificparameters,
        companycontract_specificparameters: record?.companycontract_specificparameters,
      }
    }
  }

  static onSubmitSuccessHandler() {
    return async function onSubmitSuccess(result, values, extra, meta) {
      console.log("🚀 ~ file: callbacks.js ~ line 27 ~ Callbacks ~ onSubmitSuccess ~ result, values, extra, meta", result, values, extra, meta)
    }
  }

  static onSubmitFailedHandler() {
    return async function onSubmitFailed(err, values, extra, meta) {
      console.log("🚀 ~ file: callbacks.js ~ line 27 ~ Callbacks ~ onSubmitFailed ~ err, values, extra, meta", err, values, extra, meta)
    }
  }
}

export default Callbacks
