
import React from 'react'

import { Grid, Step } from '@mui/material'
import { GridStepper } from '@front/squirtle'

import FormHeader from '@abra/elements/headers/Form'
import PrimarySection from './Section/Primary'
import PeriodSection from './Section/Period'

const Schedule = props => <>
  <Grid container justifyContent={'center'} rowSpacing={1}>
    <Grid item xs={12} sm={11} lg={10} >
      <FormHeader />
      <GridStepper groupsCount={[2]} activeStep={0} orientation={'vertical'}>
        <Step><PrimarySection /></Step>
        <Step><PeriodSection /></Step>
      </GridStepper>
    </Grid>
  </Grid>
</>

export default React.memo(Schedule)
