import React from 'react'
import { I18n } from '@front/volcanion/'

import { Grid, Icon, Box, StepLabel, StepContent, Typography, List, ListItem } from '@mui/material'

const ListItemComponent = ({ title, items, iconName, ...props }) => <Box sx={{ maxHeight: 300 }}>
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <Icon color='primary' sx={{ fontSize: 22, mr: 1 }}> {iconName} </Icon>
    <Typography variant='subtitle1'>
      {title}
    </Typography>
  </Box>
  <List dense={true} sx={{ maxHeight: 270, overflowY: 'scroll', overflow: '-moz-scrollbars-vertical' }}>
    {_.map(items, (item, key) =>
      <ListItem key={key}>
        <Typography variant='subtitle1'> - {item}</Typography>
      </ListItem>
    )}
  </List>
</Box>

const AddressSection = ({ disabled, favorites, recents, home, work, ...props }) => <>
  <StepLabel>
    <Typography variant='h5'> {I18n.t('address.label', { count: 2 })} </Typography>
  </StepLabel>
  <StepContent >
    {!disabled &&
      <Box sx={{ p: 2, boxShadow: 6, borderRadius: 1 }}>
        <Grid container rowSpacing={2}>
          <Grid item xs={6}>
            <ListItemComponent iconName={'home'} title={I18n.t('address.home')} items={home} />
          </Grid>
          <Grid item xs={6}>
            <ListItemComponent iconName={'work'} title={I18n.t('address.work')} items={work} />
          </Grid>
          <Grid item xs={6}>
            <ListItemComponent iconName={'star_border'} title={I18n.t('address.favorite', { count: 2 })} items={favorites} />
          </Grid>
          <Grid item xs={6}>
            <ListItemComponent iconName={'history'} title={I18n.t('address.recent', { count: 2 })} items={recents} />
          </Grid>
        </Grid>
      </Box>
    }
  </StepContent>
</>

export default React.memo(AddressSection)
