import React from 'react'

import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const [{ record_id, isReadOnly }] = hooks.useFormState()
  const requestSubmit = hooks.useGlobalFormFunction('trafficalertconfig_list', 'requestSubmit')
  const [isFormListReadOnly] = hooks.useGlobalFormState('trafficalertconfig_list', 'isReadOnly')

  const mergedProps = {
    isReadOnlyParentForm: isReadOnly,
    isReadOnly: isFormListReadOnly,
    submitSearchTable: requestSubmit,
    record_id
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
