import React from 'react'

import { Grid } from '@mui/material'
import { TextField } from '@front/squirtle'

const SearchForm = props =>
  <Grid container spacing={3} alignItems='flex-end'>
    <Grid item xs hidden>
      <TextField name='text' />
    </Grid>
  </Grid>


export default React.memo(SearchForm)
