import React from 'react'

import { Loader } from '@front/squirtle'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const isReady = hooks.useFormStatus('isReady')
  const [companycontract_id, commercial_package_id] = hooks.useFormState(['companycontract_id', 'commercial_package_id'])
  const canCloseOnBack = !!companycontract_id || !!commercial_package_id

  const mergedProps = {
    canCloseOnBack,
  }

  return (
    <Loader isLoading={!isReady}>
      <Component {...mergedProps} />
    </Loader>
  )
}

export default withContainer
