import React from 'react'

import { I18n } from '@front/volcanion/'
import { Box, StepLabel, StepContent, Typography } from '@mui/material'

import TranslationPanel from '@abra/panels/Translation'

const TranslationSection = props => <>
  <StepLabel><Typography variant={'h5'}> {I18n.t('translation', { count: -1 })} </Typography>
  </StepLabel>
  <StepContent>
    <Box sx={{ p: 2, boxShadow: 6, borderRadius: 1 }}>
      <TranslationPanel translations={[{ name: 'warning_trkey', labelKey: 'reason.label.one', xs: 12 }]} />
    </Box>
  </StepContent>
</>

export default React.memo(TranslationSection)
