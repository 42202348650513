import React from 'react'
import { I18n } from '@front/volcanion/'

import { Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'
import { TextField, ModelMenuSelectorField } from '@front/squirtle/'

const Row1 = ({ type, isReadOnly, ...props }) =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs>
      <TextField
        name={'name'}
        label={I18n.t('name.label', { count: 1 })}
        emptyValue='-'
        required
      />
    </Grid>
    <Grid item xs={3}>
      <ModelMenuSelectorField
        name='type'
        label={I18n.t('category.label', { count: -1 })}
        model_name='scheduletype'
        labelKeys={['name']}
        loadOnMount
        searchMode={'search'}
        disabled={!!type || !!isReadOnly}
        loadOnFocus
        selectFirst={false}
      />
    </Grid>
  </Grid>


const PrimarySection = props => <>
  <StepLabel><Typography variant='h5'> {I18n.t('info.general')}</Typography></StepLabel>
  <StepContent >
    <Box sx={{ p: 3, boxShadow: 6, br: 1 }}>
      <Grid container>
        <Row1 {...props} />
      </Grid>
    </Box>
  </StepContent>
</>

export default React.memo(PrimarySection)
