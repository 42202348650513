import React from 'react'

const withContainer = Component => ({ disabled, ...props }) => {

  const mergedProps = {
    disabled
  }
  return (
    <Component {...mergedProps} />
  )
}
export default withContainer
