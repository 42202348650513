import React from 'react'
import { I18n } from '@front/volcanion/'

import { Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'

import { ModelAutocompleteField, TextField, MenuSelectorField, SingleOptionSelectorField } from '@front/squirtle'

import { isRequired } from '@front/squirtle/utils/validators'

import UserUtils from '@front/volcanion/utils/user'

const Row1 = ({ isManager, auth_account, handleAuthAccountChange, isReadOnly, ...props }, context) =>
  <Grid container spacing={1}>
    <Grid item xs>
      <ModelAutocompleteField
        name={'customerinfo.customer_billed_id'}
        label={I18n.t('bank.customer_billed_id')}
        model_name={'user'}
        labelKeys={['label']}
        searchMode={'search'}
        autocompleteKey={'customerinfo.client_number'}
        config={{
          populate: ['customerinfo', 'info'],
          mutator: user => ({
            label: UserUtils.getFormatedName({ ...user?.info, designation: user?.customerinfo?.name }, { prefix: user?.customerinfo?.client_number }),
            ...user
          })
        }}
        placeholder={I18n.t('placeholder.client')}
      />

    </Grid>
    <Grid item xs>
      <MenuSelectorField
        name={'deferred_billing'}
        label={I18n.t('bank.deferred_billing')}
        options={[
          { label: I18n.t('quotation.payment.forbidden'), value: 'forbidden' },
          { label: I18n.t('action.allowed'), value: 'allowed', disabled: !auth_account },
          { label: I18n.t('action.mandatory'), value: 'mandatory', disabled: !auth_account }
        ]}
        disabled={isReadOnly || (isManager && !auth_account)}
        validate={isRequired}
        required
      />
    </Grid>
    <Grid item xs>
      <Grid container spacing={1}>
        <Grid item xs>
          <TextField
            name={'customerinfo.fixed_fee'}
            label={I18n.t('bank.fixed_fee')}
            type='number'
            disabled={!!isReadOnly || !isManager}
            InputProps={{
              inputProps: { style: { textAlign: 'right' } },
              endAdornment: I18n.t('fiat.eur.sign')
            }}
          />
        </Grid>
        <Grid item xs>
          <TextField
            name={'customerinfo.fee_rate'}
            label={I18n.t('bank.fee_rate')}
            type='number'
            disabled={isReadOnly || !isManager}
            InputProps={{
              inputProps: { style: { textAlign: 'right' } },
              endAdornment: I18n.t('prefix.percent')
            }}
          />
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs>
      <Grid container spacing={1}>
        <Grid item xs>
          <SingleOptionSelectorField
            name={'customerinfo.auth_account'}
            label={I18n.t('account.opening')}
            afterUserChange={handleAuthAccountChange}
            disabled={!!isReadOnly || !isManager}
            clearOnEmpty={false}
          />
        </Grid>
        <Grid item xs>
          <SingleOptionSelectorField
            name={'customerinfo.special_price'}
            label={I18n.t('price.special')}
            clearOnEmpty={false}
            disabled={!!isReadOnly || !isManager}
          />
        </Grid>
      </Grid>
    </Grid>
  </Grid>


const SubscriptionSection = ({ disabled, ...props }) => <>
  <StepLabel>
    <Typography variant='h5'>{I18n.t('info.subscription')} </Typography>
  </StepLabel>
  <StepContent >
    {!disabled && <Box sx={{ p: 2, boxShadow: 6, borderRadius: 1 }}>
      <Grid container rowSpacing={2} >
        <Grid item xs={12}>
          <Row1 {...props} />
        </Grid>
      </Grid>
    </Box>}
  </StepContent>
</>

export default React.memo(SubscriptionSection)
