import React from 'react'
import { I18n } from '@front/volcanion'
import { Typography, StepLabel, Box, StepContent } from '@mui/material'

import CustomerBillingList from './List'

const CustomerBilling = ({ disabled, record_id }) => {
  return <>
    <StepLabel>
      <Typography variant='h5'> {I18n.t('account.affiliations')} </Typography>
    </StepLabel>
    <StepContent>
      {!disabled &&
        <Box sx={{ p: 2, boxShadow: 6, borderRadius: 1 }}>
          <CustomerBillingList record_id={record_id} />
        </Box>
      }
    </StepContent>
  </>
}

export default React.memo(CustomerBilling)
