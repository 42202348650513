import React, { useCallback, useMemo } from 'react'

import { IconButton, TableCell } from '@mui/material'
import { TextCell, LinkCell } from '@abra/elements/table/cells/basics/'
import { I18n } from '@front/volcanion'

import FormatUtils from '@front/squirtle/utils/format'
import CDNSVG from '@front/volcanion/components/CDNSVG'

const Row = ({ row }) => {
  const status = useMemo(() => FormatUtils.getStatus(row, ['active', 'deleted', 'suspendedUntil', 'suspendedSince']), [row])
  const statusColor = useMemo(() => FormatUtils.getStatusColor(status), [status])

  const handleClick = useCallback(
    () => window.open(`/company/${row?.company?.company_id}/user/${row?.companyuser_id}`, '_blank'),
    [row?.companyuser_id, row?.company?.company_id])

  const companyContractService = useMemo(() => _.compact(_.flatMap(row?.company?.contracts, (contract) => _.map(row?.services, (service) => {
    if (contract?.companycontract_id === service?.contract) return { contract, service }
  }))), [row?.company?.contracts, row?.services])
  return <>
    <TableCell size={'small'}>
      <LinkCell
        value={`${row?.company?.client_id} - ${row?.company?.name}`}
        href={`/company/${row?.company?.company_id}`}
      />
    </TableCell>
    <TableCell size={'small'} >
      {_.isEmpty(companyContractService) ? (
        '-'
      ) : (
        _.map(companyContractService, ({ contract }) => (
          <LinkCell
            value={_.join(_.compact([contract?.client_id, contract?.name]), ' - ')}
            href={`/company/${contract?.company}/contract/${contract?.companycontract_id}`}
          />
        ))
      )}
    </TableCell>
    <TableCell size={'small'} >
      {_.isEmpty(companyContractService) ? (
        '-'
      ) : (
        _.map(companyContractService, ({ service }) => (
          <LinkCell
            value={_.join(_.compact([service?.client_id, service?.name]), ' - ')}
            href={`/companyservice/${service?.companyservice_id}`}
          />
        ))
      )}
    </TableCell>
    <TableCell size={'small'}><TextCell value={I18n.t(`status.${status}`)} sx={{ color: statusColor, fontWeight: 'bold' }} />  </TableCell>
    <TableCell size={'small'}><TextCell value={I18n.t(`prefix.${row?.administration_level === 'company' ? 'yes' : 'no'}`)} /></TableCell>
    <TableCell size={'small'}><TextCell value={I18n.t(`prefix.${row?.self_booking ? 'yes' : 'no'}`)} /></TableCell>
    <TableCell size={'small'}><TextCell value={I18n.t(`prefix.${row?.guest_booking ? 'yes' : 'no'}`)} /></TableCell>
    <TableCell size={'small'}><TextCell value={!!row?.booking_level ? I18n.t(`booking_level.${row?.booking_level}`) : '-'} /></TableCell>
    <TableCell size={'small'}>
      <IconButton sx={{ color: 'primary.main', fontSize: 33 }} onClick={handleClick}>
        <CDNSVG name='circle_user' />
      </IconButton>
    </TableCell>
  </>

}
export default React.memo(Row)
