import moment from 'moment'

import ScheduleUtils from '@abra/utils/schedule'

class Callbacks {
  static recordToFormHandler() {
    return function recordToForm(record) {
      return {
        ...ScheduleUtils.loadSchedule(record),
        start_time: _.get(record, 'start_time'),
        end_time: _.get(record, 'end_time')
      }
    }
  }

  static formToRecordHandler() {
    return function formToRecord(values, extra, meta) {
      return ScheduleUtils.exportSchedule(values)
    }
  }

  static recordToStateHandler() {
    return function recordToState(record) {
      return {
        title: _.get(record, 'name'),
        periodType: 'punctual'
      }
    }
  }

  static getEmptyFormHandler(type) {
    return function getEmptyFormValues() {
      return {
        generic: true,
        status: 'active',
        type
      }
    }
  }


  static getEmptyStateHandler(type) {
    return function getEmptyStateValues() {
      return {
        periodType: 'punctual'
      }
    }
  }


  static onSubmitSuccessHandler() {
    return async function onSubmitSuccess(result, values, extra, meta) {
      console.log("🚀 ~ file: callbacks.js ~ line 27 ~ Callbacks ~ onSubmitSuccess ~ result, values, extra, meta", result, values, extra, meta)
    }
  }

  static onSubmitFailedHandler() {
    return async function onSubmitFailed(err, values, extra, meta) {
      console.log("🚀 ~ file: callbacks.js ~ line 27 ~ Callbacks ~ onSubmitFailed ~ err, values, extra, meta", err, values, extra, meta)

    }
  }

  static formValidationHandler(start_time, end_time) {
    return function formValidation() {
      const meta_errors = []

      if (!!start_time && !!end_time && moment(start_time, 'HH:mm:ss').isAfter(moment(end_time, 'HH:mm:ss'))) {
        meta_errors.push({ field: 'start_time', message: "L'heure de début ne peut être plus grande que l'heure de fin" })
      }
      else if (!!start_time && !!end_time && moment(end_time, 'HH:mm:ss').isBefore(moment(start_time, 'HH:mm:ss'))) {
        meta_errors.push({ field: 'end_time', message: "L'heure de début ne peut être plus grande que l'heure de fin" })
      }
      return meta_errors
    }
  }
}

export default Callbacks
