
import React from 'react'

import { Grid, Step } from '@mui/material'
import { GridStepper } from '@front/squirtle'

import FormHeader from '@abra/elements/headers/Form'

import PrimarySection from './Section/Primary'
import BookingSection from './Section/Booking'
import NoShowSection from './Section/NoShow'
import LateCancelSection from './Section/LateCancel'
import BillingSection from './Section/Billing'
import TipsSection from './Section/Tips'
import FeatureSection from './Section/Feature'

const SpecificParameter = ({ canCloseOnBack }) => <>
  <Grid container justifyContent='center' alignItems='center'>
    <Grid container item xs={12} sm={10} xl={8} rowSpacing={1}>
      <Grid item xs={12} >
        <FormHeader closeOnBack={!!canCloseOnBack} />
      </Grid>
      <Grid item xs={12} >
        <GridStepper groupsCount={[6]} activeStep={0} orientation="vertical">
          <Step> <PrimarySection /> </Step>
          <Step> <FeatureSection /> </Step>
          {/* TODO: when story
          <Step> <BookingSection /> </Step> */}
          <Step> <NoShowSection /> </Step>
          {/* TODO: when story
          <Step> <LateCancelSection /> </Step> */}
          <Step> <BillingSection /> </Step>
          {/* TODO: when story
          <Step> <TipsSection /> </Step> */}
        </GridStepper>
      </Grid>
    </Grid>
  </Grid>
</>

export default React.memo(SpecificParameter)
