import React from 'react'
import { I18n } from '@front/volcanion/'

import { Typography, Box, StepLabel, StepContent, Grid, Divider } from '@mui/material'
import { TimeField, OptionSelectorField } from '@front/squirtle/'

import { CustomDayTable } from '@abra/elements'

const Row1 = ({ applicable_days }) =>
  <Grid container item xs={12} alignItems='flex-end'>
    <Grid item xs={6}>
      <OptionSelectorField
        name={'applicable_days'}
        label={I18n.t('day.label', { count: 2 })}
        options={[
          { label: I18n.t('day.monday.short'), value: 'monday' },
          { label: I18n.t('day.tuesday.short'), value: 'tuesday' },
          { label: I18n.t('day.wednesday.short'), value: 'wednesday' },
          { label: I18n.t('day.thursday.short'), value: 'thursday' },
          { label: I18n.t('day.friday.short'), value: 'friday' },
          { label: I18n.t('day.saturday.short'), value: 'saturday' },
          { label: I18n.t('day.sunday.short'), value: 'sunday' }
        ]}
        multiple
      />
    </Grid>
    <Grid container item xs={3} spacing={6}>
      <Grid item xs>
        <TimeField
          name={'start_time'}
          format={'HH:mm'}
          inputFormat={'HH:mm'}
          label={I18n.t('hour.start')}
          inputProps={{ style: { textAlign: 'right' } }}
        />
      </Grid>
      <Grid item xs>
        <TimeField
          name={'end_time'}
          label={I18n.t('hour.end')}
          inputProps={{ style: { textAlign: 'right' } }}
        />
      </Grid>
    </Grid >
  </Grid >

const PeriodSection = ({ applicable_days }) => <>
  <StepLabel><Typography variant={'h5'}>{I18n.t('schedule.period')}</Typography></StepLabel>
  <StepContent>
    <Box sx={{ p: 3, boxShadow: 6, borderRadius: 1 }}>
      <Grid container rowSpacing={3}>
        <Row1 applicable_days={applicable_days} />
        <Grid container item xs={12} rowSpacing={1} justifyContent={'center'}>
          <CustomDayTable type={'include'} model_name={'scheduledayinclude'} key_id={'scheduleday_include_id'} search_id={'scheduledayincludes_list'} />
          <Grid item xs={10}><Divider /></Grid>
          <CustomDayTable type={'exclude'} model_name={'scheduledayexclude'} key_id={'scheduleday_exclude_id'} search_id={'scheduledayexcludes_list'} />
        </Grid>
      </Grid>
    </Box>
  </StepContent>
</>

export default React.memo(PeriodSection)
