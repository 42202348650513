import React, { useCallback } from 'react'
import { ModelForm, hooks } from '@front/volcanion'

import Callbacks from './callbacks'

const withForm = Component => ({ record_id, type: inputType, ...props }) => {
  const search_params = hooks.useSearchParams()
  const type = inputType || _.head(_.get(search_params, 'type'))

  const formProps = {
    name: 'schedule_form',
    model_name: 'schedule',
    record_id: record_id,
    related_states: [],
    related_searches: [],
    getEmptyState: useCallback(Callbacks.getEmptyStateHandler(), []),
    getEmptyForm: useCallback(Callbacks.getEmptyFormHandler(type), [type]),
    recordToForm: useCallback(Callbacks.recordToFormHandler(), []),
    recordToState: useCallback(Callbacks.recordToStateHandler(), []),
    formToRecord: useCallback(Callbacks.formToRecordHandler(), []),
    populate: [
      'include_days'
    ],
    initialState: { isReadOnly: !!record_id, type },
    onSubmitSuccess: useCallback(Callbacks.onSubmitSuccessHandler(), []),
    onSubmitFailed: useCallback(Callbacks.onSubmitFailedHandler(), [])
  }

  const mergedProps = {
  }

  return (
    <ModelForm {...formProps}>
      <Component {...mergedProps} />
    </ModelForm>
  )
}

export default withForm

