import React from 'react'

import { Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'
import { NumberField, SingleOptionSelectorField } from '@front/squirtle'
import { I18n } from '@front/volcanion'

const Row1 = (props) =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs={4}>
      <SingleOptionSelectorField
        name='immediate_order_enabled'
        optionLabel={I18n.t('order.immediate.longLabel', { count: -1 })}
        clearOnEmpty={false}
      />
    </Grid>
    <Grid item xs={4}>
      <SingleOptionSelectorField
        name='dest_mandatory'
        optionLabel={I18n.t('address.dest_mandatory')}
        clearOnEmpty={false}
      />
    </Grid>
  </Grid>

const Row2 = ({ required_delay, enableDelay }) =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs={4}>
      <SingleOptionSelectorField
        name='planned_order_enabled'
        optionLabel={I18n.t('order.planned.longLabel', { count: -1 })}
        afterUserChange={enableDelay}
        clearOnEmpty={false}
      />
    </Grid>
    {!!required_delay && (
      <>
        <Grid item xs={4}>
          <NumberField
            label={I18n.t('specificparameter.min_delay')}
            name='min_delay'
            type='number'
            minValue={0}
            InputProps={{
              style: { textAlign: 'center' },
              endAdornment: <Box fontSize='12' color='text.label'> ({I18n.t('minute.label.short')}) </Box>,
            }}
            required={required_delay}
          />
        </Grid>
        <Grid item xs={4}>
          <NumberField
            label={I18n.t('specificparameter.max_delay')}
            name='max_delay'
            type='number'
            minValue={0}
            InputProps={{
              style: { textAlign: 'center' },
              endAdornment: <Box sx={{ fontSize: 12, color: 'text.label' }} > ({I18n.t('day.label', { count: 1 })}) </Box>
            }}
            required={required_delay}
          />
        </Grid>
      </>
    )}
  </Grid>

const FeatureSection = (props) => <>
  <StepLabel>
    <Typography variant='h5'>{I18n.t('feature.label', { count: -1 })}</Typography>
  </StepLabel>
  <StepContent>
    <Box sx={{ p: 2, boxShadow: 6, borderRadius: 1 }}>
      <Grid container rowSpacing={2}>
        <Row1 {...props} />
        <Row2 {...props} />
      </Grid>
    </Box>
  </StepContent>
</>

export default React.memo(FeatureSection)
