import React, { useCallback } from 'react'

import { hooks } from '@front/volcanion'
import { Loader } from '@front/squirtle'

import Callbacks from './callbacks'

const withContainer = Component => props => {
  const isReady = hooks.useFormStatus('isReady')
  const [start_time, end_time] = hooks.useFieldValues(['start_time', 'end_time'])

  const mergedProps = {
  }

  const formValidation = useCallback(Callbacks.formValidationHandler(start_time, end_time), [start_time, end_time])

  hooks.useFormValidation(formValidation)


  return <Loader isLoading={!isReady}>
    <Component {...mergedProps} {...props} />
  </Loader>
}

export default withContainer
