import React, { useCallback } from 'react'
import { hooks, I18n } from '@front/volcanion'

import { Grid } from '@mui/material'
import { TableHeader, TableRow, SearchFrame, SearchForm, SearchTable, FormContent, TableDialogAction, ConfirmationDialog, TableRowActions } from '@front/squirtle'

import Row from './Row'
import Header from './Header'
import Form from './Form'

import Callbacks from './callbacks'

const PaymentMethodList = ({ record_id }) => {
  const filterToForm = useCallback(Callbacks.filterToFormHandler(), [])
  const filterToState = useCallback(Callbacks.filterToStateHandler(record_id), [record_id])
  const formToFilter = useCallback(Callbacks.formToFilterHandler(), [])
  const [deleteCard] = hooks.useModelFunction('paymentmethod', 'delete')

  return <Grid item xs={12}>
    <ConfirmationDialog name={'driver_delete'} onConfirm={({ row }) => deleteCard({
      payment_method_id: row?.payment_method_id,
      user: row?.user
    })} />
    <SearchFrame model_name={'paymentmethod'} allowCreate={false}>
      <SearchForm
        disableButton={true}
        submitOnMount={!!record_id}
        filterToForm={filterToForm}
        filterToState={filterToState}
        formToFilter={formToFilter}
      >
        <FormContent><Form /></FormContent>
      </SearchForm>
      <SearchTable
        tableProps={{
          onRowClick: null,
          enableToolbar: true,
          enablePagination: true,
          enableRowActions: true,
          enableRowSelection: false,
          enableGlobalActions: true,
          enableGlobalActionsPlacement: 'top',
          disableCancelRowLabel: true,
          disableSaveRowLabel: true
        }}
      >
        <TableHeader><Header /></TableHeader>
        <TableRow><Row /></TableRow>
        <TableRowActions>
          <TableDialogAction label={I18n.t('action.delete')} name={"driver_delete"} getDialogExtra={() => ({ title: I18n.t('payment.delete.title'), description: I18n.t('payment.delete.description') })} />
        </TableRowActions>

      </SearchTable>
    </SearchFrame>
  </Grid>
}

export default React.memo(PaymentMethodList)
