import React from 'react'
import { I18n } from '@front/volcanion/'

import { Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'
import { TextField, SingleOptionSelectorField, MenuSelectorField } from '@front/squirtle/'

const Row1 = ({ type, afterChangeType }) =>
  <Grid container item xs={12} spacing={2}>
    <Grid item xs={1}>
      <SingleOptionSelectorField name='active' label={I18n.t('active')} clearOnEmpty={false} />
    </Grid>
    <Grid item xs={3}>
      <MenuSelectorField
        label={I18n.t('type.label', { count: 1 })}
        name={'type'}
        options={[
          { label: I18n.t('traffic_alert.type.warning'), value: 'warning' },
          { label: I18n.t('traffic_alert.type.error'), value: 'error' }
        ]}
        selectFirst
        afterChange={afterChangeType}
        allowNone={false}
      />
    </Grid>
    <Grid item xs>
      <TextField name={'name'} label={I18n.t('name.label', { count: 1 })} required />
    </Grid>
    <Grid item xs={3} hidden={type === 'error'}>
      <TextField
        name={'margin'}
        label={I18n.t('quotation.marge')}
        type='number'
        required={type === 'warning'}
        InputProps={{
          inputProps: { sx: { textAlign: 'right' } },
          endAdornment: I18n.t('minute.label.short')
        }}
      />
    </Grid>
  </Grid>

const PrimarySection = props => <>
  <StepLabel> <Typography variant={'h5'}>{I18n.t('info.general')}</Typography>
  </StepLabel>
  <StepContent>
    <Box sx={{ p: 2, boxShadow: 6, borderRadius: 1 }}>
      <Grid container rowSpacing={3}>
        <Row1 {...props} />
      </Grid>
    </Box>
  </StepContent>
</>

export default React.memo(PrimarySection)
