
import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => (props) => {
  const [record_id] = hooks.useFormState('record_id')
  const [type] = hooks.useFieldValues(['type'])
  const setFormValues = hooks.useFormChange()

  const afterChangeType = useCallback(value => {
    value === 'error' && setFormValues([{ field: 'margin', value: undefined }])
  }, [setFormValues, type])

  const mergedProps = {
    afterChangeType,
    record_id,
    type
  }

  return <Component {...mergedProps} {...props} />
}

export default withContainer
