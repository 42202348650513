import React, { useCallback } from 'react'
import { hooks, ModelForm } from '@front/volcanion'

import Callbacks from './callbacks'

const withForm = Component => ({ record_id }) => {
  const search_params = hooks.useSearchParams()
  const companycontract_id = _.head(_.get(search_params, 'companycontract_id'))
  const commercial_package_id = _.head(_.get(search_params, 'commercialpackage_id'))

  const formProps = {
    name: 'specificparameter_form',
    model_name: 'specificparameter',
    record_id,
    related_states: [],
    related_searches: [],
    getEmptyForm: useCallback(Callbacks.getEmptyFormHandler(), []),
    getEmptyState: useCallback(Callbacks.getEmptyStateHandle(companycontract_id, commercial_package_id), [companycontract_id, commercial_package_id]),
    recordToForm: useCallback(Callbacks.recordToFormHandler(), []),
    recordToState: useCallback(Callbacks.recordToStateHandler(), []),
    formToRecord: useCallback(Callbacks.formToRecordHandler(), []),
    populate: [
      'billing_criteria',
      'companycontract_specificparameters',
      'commercialpackage_specificparameters'
    ],
    initialState: { isReadOnly: !!record_id },
    onSubmitSuccess: useCallback(Callbacks.onSubmitSuccessHandler(), []),
    onSubmitFailed: useCallback(Callbacks.onSubmitFailedHandler(), []),
    submitChangesOnly: false
  }

  const mergedProps = {
  }

  return <>
    <ModelForm {...formProps}>
      <Component {...mergedProps} />
    </ModelForm>
  </>
}

export default withForm
