
import React from 'react'

import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const [type] = hooks.useFormState('type')
  const [isReadOnly] = hooks.useFormState('isReadOnly')

  const mergedProps = {
    type,
    isReadOnly
  }

  return <Component {...mergedProps} {...props} />
}

export default withContainer
