import React from 'react'

import { ConfirmationDialog } from '@front/squirtle'
import { SuspensionPopupForm } from '@abra/elements/Suspensions'

const Dialogs = ({ resetPassword, sendWelcomeEmail, user_auth_id }) => (
  <>
    <SuspensionPopupForm record_id={user_auth_id} />
    <ConfirmationDialog name={'confirm_reset_password'} onConfirm={resetPassword} />
    <ConfirmationDialog name={'confirm_welcome_email'} onConfirm={sendWelcomeEmail} />
  </>
)

export default React.memo(Dialogs)
