import React, { useCallback } from 'react'

import Row from './Row'
import Header from './Header'
import { FormTable, TableHeader, TableRow } from '@front/squirtle'

const CustomerBillingList = ({ record_id }) => {
  return (
    <FormTable
      model_name='user'
      config={{
        populate: ['customerinfo', 'info'],
        initial_filter: {},
        forced_filter: { customerinfo: { customer_billed_id: record_id } },
        watchers: [record_id],
        watch_type: 'index',
        validate_filter: useCallback(() => !!record_id, [record_id]),
      }}
    >
      <TableHeader><Header /></TableHeader>
      <TableRow ><Row /></TableRow>
    </FormTable>
  )
}

export default React.memo(CustomerBillingList)
