import React from 'react'
import { I18n } from '@front/volcanion/'

import { Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'

import { DateTimeField } from '@front/squirtle'

const Row1 = props =>
  <Grid container item xs={12} spacing={1} >
    <Grid item xs>
      <DateTimeField
        name={'createdAt'}
        dateProps={{ label: I18n.t('date.create') }}
        disabled
        compact
      />
    </Grid>
    <Grid item xs>
      <DateTimeField
        name={'customerinfo.first_order_date'}
        dateProps={{ label: I18n.t('order.first') }}
        disabled
        compact
      />
    </Grid>
    <Grid item xs>
      <DateTimeField
        name={'customerinfo.last_order_date'}
        dateProps={{ label: I18n.t('order.last') }}
        disabled
        compact
      />
    </Grid>
  </Grid>

const SecondarySection = ({ disabled, ...props }) => <>
  <StepLabel>
    <Typography variant='h5'> {I18n.t('info.utils')} </Typography>
  </StepLabel>
  <StepContent >
    {!disabled && <Box sx={{ p: 2, boxShadow: 6, borderRadius: 1 }}>
      <Grid container rowSpacing={2}>
        <Row1 {...props} />
      </Grid>
    </Box>}
  </StepContent>
</>

export default React.memo(SecondarySection)
