
import React from 'react'
import { hooks } from '@front/volcanion/'

const withContainer = Component => props => {
  const generic = hooks.useFormStateValue('generic')
  const [companycontract_id] = hooks.useFormState(['companycontract_id'])
  const [companycontract] = hooks.useModel('companycontract', [companycontract_id], { single: true })
  const companyContractLabel = _.join(_.compact([companycontract?.client_id, companycontract?.name]), '-')
  const authorized_formulas = companycontract?.formula

  const mergedProps = {
    generic,
    companycontract_id,
    companyContractLabel,
    authorized_formulas,
  }

  return <Component {...mergedProps} />
}

export default withContainer
