import React from 'react'
import Component from './component'
import withContainer from './container'
import withForm from './form'

export {
  Component,
  withContainer,
  withForm
}

export default React.memo(withForm(withContainer(Component)))
